<template>
  <Layout>
    <div class="row mb-5">
      <div class="col-md-4 offset-md-4 pb-4">
        <div class="registration-form pt-5 ps-4 pe-4 pb-3">
            <p class="welcome text-start" style="font-size: 16pt">{{$t("forgotten_your_password")}}</p>
            <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>{{regSucess}}</b-alert>
            <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}</b-alert>
            <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
            <p class="create-your-account text-start" style="font-size: 11pt">{{$t("enter_your_email_address_bellow")}}</p>
            <div>
              <label for="email" class="form-label">{{$t("your_email")}}</label>
              <div class="form-icon right">
                <input type="email" class="form-control form-control-icon" id="email"
                       v-model="email" :class="{'is-invalid': submitted && v$.email.$error,}"
                       :placeholder="$t('type_in')">
                <i class="ri-mail-line red-color"></i>
              </div>
              <div v-for="(item, index) in v$.email.$errors" :key="index" class="red-color">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
            <div class="mt-3 d-grid gap-2">
              <button @click="tryToForgetPassword" class="submit">{{$t("reset_my_password")}}</button>
            </div>

          <div class="d-flex justify-content-between link-sign-in text-center mt-3">
            <RouterLink to="/login">
              <span class="red-color">&nbsp;{{$t("sign_in")}}</span>
            </RouterLink>
          </div>
          </div>
        </div>
    </div>
    <div style="margin-bottom: 100px"></div>
  </Layout>
</template>

<script>
import {
  required,
  email,
  helpers
} from "@vuelidate/validators";

import userService from "@/apis/users-services";
import Layout from "@/layouts/main";
import useVuelidate from "@vuelidate/core";
export default {
  name: "forgot-password",
  setup(){
    return {
      v$: useVuelidate()
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      email: "",
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      regSucess: null,
      registerSuccess: false,
    };
  },
  validations() {
    return{
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
    }
  },
  mounted() {
    document.documentElement.setAttribute("data-layout", "horizontal");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-topbar", "dark");
    document.documentElement.setAttribute("data-sidebar-size", "sm");
    document.documentElement.setAttribute("data-layout-position", "fixed"); //scrollable
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    async tryToForgetPassword() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }else {
        this.tryingToRegister = true;
        // Reset the regError if it existed.
        this.regError = null;
        this.isRegisterError = false;

        this.$isLoading(true); // show loading screen
        await userService.forgetPassword(this.email)
            .then((response) => {
              const {status, message} = response.data;
              if (status === "success"){
                this.tryingToRegister = false;
                this.registerSuccess = true;
                this.regSucess = message;
              }else {
                this.regError = message;
                this.tryingToRegister = false;
                this.isRegisterError = true;
              }
            })
            .catch((error) => {
              this.tryingToRegister = false;
              this.regError = error ? error : "";
              this.isRegisterError = true;
            })
            .finally(() => {
              this.$isLoading(false);
            })
      }
    },
  }
}
</script>

<style scoped>

</style>